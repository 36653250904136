import { ApiResponse } from 'apps/kuiper/src/app/common/models/api-response.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LookupFilter, LookupOptionItem } from './lookup-field-item';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LookupFieldService {
  constructor(private readonly http: HttpClient) {}

  getLookupOptions(url: string, page: number, filters?: LookupFilter[]) {
    let params = new HttpParams().append('page', page);
    if (filters?.length) {
      filters.forEach(filter => {
        params = params.append('filters[' + filter.key + ']', filter.value);
      });
    }
    return this.http.get<ApiResponse<LookupOptionItem>>(url, {
      params,
    });
  }
}
