import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyValue',
  standalone: true,
})
export class KeyValuePipe implements PipeTransform {
  transform(option: any, key: string): string {
    return option[key];
  }
}
